<template>
    <div class="main home-main">
        <div class="header" :style="{'background-image': `url(${require('@/assets/images/my_header_bg.png')})`}">
            <QsNavbar title="我的" :leftArrow="false"></QsNavbar>
            <div class="header-body">
                <swiper class="swiper" :options="swiperOption" ref="levalSwiper">
                    <swiper-slide class="level-lv1">
                        <div class="level-box ">
                            <div class="wrap" :style="{'background-image': `url(${require('@/assets/images/lv1_bg.png')})`}">
                                <div class="active" v-if="!isSubscribe && !isRegister">当前等级</div>
                                <div class="body">
                                    <img :src="require('@/assets/images/lv1.png')" class="icon"/>
                                    <div class="meta">
                                        <div class="name"><span>会员等级</span><strong>LV1</strong></div>
                                        <div class="info">会员享2项特权</div>
                                    </div>
                                    <div class="upgrade" @click="onRegister"  v-if="!isSubscribe && !isRegister">验证升级</div>
                                </div>
                            </div>
                        </div>
                        <div class="level-authority d-flex">
                            <div class="item">
                                <img :src="require('@/assets/images/icon_look_history.png')" class="icon"/>
                                <p>查看过往摘要</p>
                            </div>
                            <div class="item">
                                <img :src="require('@/assets/images/icon_try_read.png')" class="icon"/>
                                <p>试读部分文章</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="level-box">
                            <div class="wrap" :style="{'background-image': `url(${require('@/assets/images/lv2_bg.png')})`}">
                                <div class="active"  v-if="isSubscribe && !isRegister">当前等级</div>
                                <div class="body">
                                    <img :src="require('@/assets/images/lv2.png')" class="icon"/>
                                    <div class="meta">
                                        <div class="name"><span>会员等级</span><strong>LV2</strong></div>
                                        <div class="info">会员享3项特权</div>
                                    </div>
                                    <div class="upgrade" @click="onRegister"  v-if="isSubscribe && !isRegister">验证升级</div>
                                </div>
                            </div>
                        </div>
                        <div class="level-authority d-flex">
                            <div class="item">
                                <img :src="require('@/assets/images/icon_read_month.png')" class="icon"/>
                                <p>阅读本月文章</p>
                            </div>
                            <div class="item">
                                <img :src="require('@/assets/images/icon_fav.png')" class="icon"/>
                                <p>收藏文章</p>
                            </div>
                            <div class="item">
                                <img :src="require('@/assets/images/icon_forward.png')" class="icon"/>
                                <p>转发文章</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="level-lv3">
                        <div class="level-box">
                            <div class="wrap" :style="{'background-image': `url(${require('@/assets/images/lv3_bg.png')})`}">
                                <div class="active" v-if="isSubscribe && isRegister">当前等级</div>
                                <div class="body">
                                    <img :src="require('@/assets/images/lv3.png')" class="icon"/>
                                    <div class="meta">
                                        <div class="name"><span>会员等级</span><strong>LV3</strong></div>
                                        <div class="info">会员享4项特权</div>
                                    </div>
                                    <!-- <div class="upgrade" @click="onRegister"  v-if="isSubscribe && isRegister">验证升级</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="level-authority d-flex">
                            <div class="item">
                                <img :src="require('@/assets/images/icon_update_notify.png')" class="icon"/>
                                <p>文章下载</p>
                            </div>
                            <div class="item">
                                <img :src="require('@/assets/images/icon_read.png')" class="icon"/>
                                <p>阅读所有文章</p>
                            </div>
                            <div class="item">
                                <img :src="require('@/assets/images/icon_fav.png')" class="icon"/>
                                <p>收藏文章</p>
                            </div>
                            <div class="item">
                                <img :src="require('@/assets/images/icon_forward.png')" class="icon"/>
                                <p>转发文章</p>
                            </div>
                        </div>
                    </swiper-slide>
                 </swiper>
            </div>
        </div>
        <van-tabs v-model="tabId" @click="onSwitchTab" class="leader-category-tabs">
            <van-tab title="我的收藏" name="myfav">
                <div class="q-box">
                    <van-list class="body"
                        v-model="favs.loading"
                        :finished="favs.finished"
                        finished-text="没有更多了"
                        @load="onGetFavs">
                        <router-link :to="{path:'/leader/show',query:{id:item.id}}" tag="div" class="d-flex list-item article-box " v-for="item in favs.items" :key="item.id" >
                            <div class="flex-1">
                                <div>{{item.title}}</div>
                            </div>
                            <img :src="item.img" class="thumb" width="120px" height="80px"/>
                        </router-link>
                    </van-list>
                </div>
            </van-tab>
            <van-tab title="浏览历史" name="history">
                <div class="q-box">
                    <van-list class="body"
                        v-model="histories.loading"
                        :finished="histories.finished"
                        finished-text="没有更多了"
                        @load="onGetHistories">
                        <router-link :to="{path:'/leader/show',query:{id:item.id}}" tag="div" class="d-flex list-item article-box " v-for="item in histories.items" :key="item.id" >
                            <div class="flex-1">
                                <div>{{item.title}}</div>
                            </div>
                            <img :src="item.img" class="thumb" width="120px" height="80px"/>
                        </router-link>
                    </van-list>
                </div>
            </van-tab>
        </van-tabs>
        <QsTabbar tab-id="my"></QsTabbar>
    </div>
</template>

<script>
import { Dialog } from 'vant';
import { mapState,mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'


export default {
    data(){
        return{
            histories:{
                items:[],
                finished:false,
                loading:false,
                post:{
                    page:0,
                }
            },
            favs:{
                items:[],
                finished:false,
                loading:false,
                post:{
                    page:0,
                }
            },
            swiperOption: {
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 10,
                // effect:'cube'
                // initialSlide:this.userLevel,
            },
            tabId:'myfav',

        }
    },
    mounted(){
        this.$refs["levalSwiper"].$swiper.slideTo(this.userLevel, 0, false);
    },
    methods:{
        onGetFavs(){
            this.favs.post.page++;
            this.$ajax.post("client/my/favs",this.favs.post).then(res=>{
                this.favs.items = this.favs.items.concat(res.data.data);
                this.favs.loading = false;
                if(res.data.current_page>=res.data.last_page){
                    this.favs.finished = true;
                }
            }).catch(e=>{})
        },
        onGetHistories(){
            this.histories.post.page++;
            this.$ajax.post("client/my/histories",this.histories.post).then(res=>{
                this.histories.items = this.histories.items.concat(res.data.data);
                this.histories.loading = false;
                if(res.data.current_page>=res.data.last_page){
                    this.histories.finished = true;
                }                
            }).catch(e=>{})
        },        
        onRegister(){
            if(!this.isSubscribe){
                Dialog.alert({
                    message: '请关注公众号后方可查看!',
                    theme: 'round-button',
                    className:"register-dialog",
                    closeOnClickOverlay:true,
                    confirmButtonColor:'#0099D8'
                })
                return;
            }
            Dialog.alert({
                message: '若想查看更多文章内容\n请前往验证升级',
                theme: 'round-button',
                className:"register-dialog",
                closeOnClickOverlay:true,
                confirmButtonColor:'#0099D8'
            }).then(() => {
                this.$router.push("/public/register");
            }).catch(e=>{
                console.log("cancel");
            });
        },
        onSwitchTab(){

        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    computed:{
        ...mapState({
            user:state=>state.user
        }),
        ...mapGetters(['isLogin','isSubscribe','isRegister']),
        userLevel(){
            if( this.isSubscribe && this.isRegister){
                return 2;
            }else if(this.isSubscribe){
                return 1;
            }else{
                return 0;
            }
        },
        swiperWidth(){
            let bodyW = document.body.clientWidth;
            if(bodyW>750){
                bodyW = 750;
            }

            return bodyW / 375 * 343;
        }
    }    
}
</script>

<style lang="scss">

.home-main{
    .header{background-size: 100%;background-position: center;}
    .header-body{padding:16px 0;}
    .van-nav-bar{background:none;}
    .van-nav-bar__title{color:#fff;}
    .van-hairline--bottom::after{border-bottom:none;}

    .swiper {
        width: 100%;
        .swiper-slide {
            width:9.3rem;
            max-width:686px;
        }
    }

}

</style>

<style lang="scss" scoped>
.my-head-box{
    .flex-1{margin:0 8px;}
}



.level-box{
    display:flex;

    .side{width:8px;}
    .side.left{margin-right: 8px;}
    .side.right{margin-left: 8px;}
    .wrap{flex:1;background-size: 100%;background-repeat: no-repeat;position: relative;
        .active{width: 65px;height: 24px;background: linear-gradient(135deg, #FF8F8F 0%, #FF5454 100%);border-radius: 16px 0px 16px 0px;position: absolute;left:0;top:0;font-size: 12px;text-align:center;color:#fff;}
        .body{display: flex;align-items: center;height: 96px;padding-left:18px;padding-right:12px;
            .icon{width:36px;height:36px;}
            .meta{flex:1;color:#fff;
                .name{font-size: 20px;
                    strong{margin-left:4px;font-size: 28px;}
                }
                .info{font-size: 12px;color: rgba(255, 255, 255, 0.5);}
            }
            .upgrade{width: 80px;height: 32px;line-height:32px;background: linear-gradient(90deg, #F6DDC4 0%, #F2B994 100%);border-radius: 24px;color:#362C2E;font-weight:$fw;text-align: center;font-size: 14px;}
        }
    }
}
.level-authority{
    margin-top:16px;
    justify-content: space-evenly;
    .item{
        text-align: center;font-size:13px;color: rgba(255, 255, 255, 0.8);
        .icon{width:36px;height:36px;}
    }
}
</style>

